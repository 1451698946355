/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/index';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './styles/theme';
import {
  createTheme,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale/';
import { generateApiApp, generateThemeDefault } from './theme/api.theme';

const generateClassName = createGenerateClassName({
  productionPrefix: 'solucoes',
  seed: 'login',
});

function Index() {
  const [themeApp, setThemeApp] = useState(null);
  const clearStorage = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    generateThemeDefault();
  }, []);

  useEffect(() => {
    clearStorage();
    generateApiApp()
      .then(theme => {
        theme.palette.type = 'light';
        const customTheme = createTheme({ ...theme }, ptBR);
        setThemeApp(customTheme);
      })
      .catch(erro => console.log('main erro pegar tehema ', erro));
  }, []);

  return (
    <StylesProvider generateClassName={generateClassName}>
      {themeApp && (
        <ChakraProvider theme={theme}>
          <ThemeProvider theme={themeApp}>
            <Routes />
          </ThemeProvider>
        </ChakraProvider>
      )}
    </StylesProvider>
  );
}
export default React.memo(Index);
