import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import Button2 from '../components/Buttons/ConfirmButton';
import { useParams, useLocation } from 'react-router-dom';
import { api_multipague_configuracao } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingBtn from '../components/Buttons/ConfirmButton';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Todos os Direitos Reservados.
      <Link color="inherit" href="https://grupomultiplica.com.br/">
        Grupo Multiplica
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export default function ConfirmEmail(props) {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const [oldFinancial, setOldFinancial] = useState('');
  const [financial, setFinancial] = useState('');
  const [confirmFinancial, setConfirmFinancial] = useState('');
  const [autorizacao, setAutorizacao] = useState('');
  const [loadButton, setLoadButton] = useState(false);
  const [ativado, setAtivado] = useState(true);
  const { tk } = useParams();
  const usuario = history.location?.state.usuario;
  const usuarioLocalStorage = localStorage.getItem('usuario-esqueci-senha');

  var md5 = require('md5');
  const theme = useTheme();

  console.log('theme mui', theme);

  const options = {
    username: !!usuario ? usuario : usuarioLocalStorage,
    codigoSeguranca: autorizacao,
    senha: financial,
    confirmacaoSenha: confirmFinancial,
  };

  const [values, setValues] = React.useState({
    showPassword: false,
    showPasswordConfirm: false,
    showPasswordConfirmFin: false,
    showPasswordFin: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };
  const handleMouseDownPasswordConfirm = event => {
    event.preventDefault();
  };

  const handleAbrirConta = () => {
    // setLoadButton(true)
    api_multipague_configuracao
      .post(`/usuario/senha/redefinir`, options)
      .then(response => {
        toast.success('Ok! Sua senha de acesso foi alterada com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = '/login';
        }, 3000);
        // setLoadButton(false)
      })
      .catch(error => {
        console.log(error);
        // setLoadButton(false)
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toast.error(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function check() {
    if (
      document.getElementById('financialpassword').value ==
      document.getElementById('checkfinancialpassword').value
    ) {
      document.getElementById('message').style.color = 'green';
      document.getElementById('message').innerHTML = 'correto';
      setAtivado(false);
    } else {
      document.getElementById('message').style.color = 'red';
      document.getElementById('message').innerHTML = 'as senhas não conferem';
    }
  }

  const [disableButton, setDisableButton] = useState(true);

  const handleChangeNovaSenha = e => {
    const newValue = e.target.value;

    if (
      newValue.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
      )
    ) {
      setFinancial('');
      setError(false);
      setDisableButton(false);
    } else {
      setError('A senha informada não atende aos requisitos');
      setDisableButton(true);
    }
    setFinancial(newValue);
  };

  const [error, setError] = useState('');

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon
            style={{
              color: theme.palette.getContrastText(
                theme.palette.secondary.main,
              ),
            }}
          />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: '15px' }}
        >
          Troca de senha
        </Typography>
        <Typography
          component="body1"
          variant="body1"
          style={{ marginBottom: '15px' }}
        >
          Confira seu email e digite no campo abaixo o código de autorização que
          você recebeu
        </Typography>
        <form className={classes.form} noValidate>
          <InputLabel
            style={{ marginBottom: '5px' }}
            htmlFor="age-native-label-placeholder"
          >
            Código de autorização
          </InputLabel>
          <TextField
            style={{ marginBottom: '20px', color: 'red' }}
            margin="dense"
            variant="outlined"
            size="small"
            inputComponent={TextMaskCustom}
            // type={values.showPassword ? 'text' : 'password'}
            required
            fullWidth
            id="autorizacao"
            name="autorizacao"
            onChange={e => {
              setAutorizacao(e.target.value);
            }}
            autoFocus
          />
          {/* <InputLabel style={{marginBottom:'5px'}} htmlFor="age-native-label-placeholder">
        Digite a sua senha antiga
            </InputLabel>
          <TextField
            style={{marginBottom:"20px"}}
            margin="dense"
            variant="outlined"
            size="small"
            type={values.showPassword ? 'text' : 'password'}
            required
            fullWidth
            id="oldfinancialpassword"
            name="oldfinancialpassword"
            onChange={(e) => { setOldFinancial(e.target.value) }}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          /> */}
          <InputLabel
            style={{ marginBottom: '5px' }}
            htmlFor="age-native-label-placeholder"
          >
            Digite a sua nova senha
          </InputLabel>
          <TextField
            style={{ marginBottom: '20px' }}
            margin="dense"
            variant="outlined"
            size="small"
            type={values.showPassword ? 'text' : 'password'}
            required
            fullWidth
            id="financialpassword"
            name="financialpassword"
            error={error}
            onBlur={handleChangeNovaSenha}
            // onChange={(e) => { setFinancial(e.target.value) }}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <span data-id="TESTE-VERSAO">
            Obrigatório no mínimo 6 caracteres, sendo 1 letra maiúscula e 1
            caractere especial.
          </span>
          <InputLabel
            style={{ marginBottom: '5px', marginTop: '10px' }}
            htmlFor="age-native-label-placeholder"
          >
            Confirme a sua nova senha
          </InputLabel>
          <TextField
            margin="dense"
            variant="outlined"
            size="small"
            color="primary"
            type={values.showPasswordConfirm ? 'text' : 'password'}
            required
            fullWidth
            onKeyUp={check}
            id="checkfinancialpassword"
            name="checkfinancialpassword"
            onChange={e => {
              setConfirmFinancial(e.target.value);
            }}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPasswordConfirm}
                >
                  {values.showPasswordConfirm ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <div>
            <span id="message"></span>
          </div>
          {/* <input hidden value={this.props.location.search.tk} onChange={(e) => { setTk(e.target.value) }} /> */}

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              // type="submit"
              disabled={disableButton}
              isLoading={loadButton}
              onClick={handleAbrirConta}
            >
              Confirmar
            </Button>
          </Box>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
        <ToastContainer />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
