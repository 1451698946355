import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import InputMask from 'react-input-mask';

import axios from 'axios';
import { api_multipague_configuracao } from '../services/api';

import {
  useBreakpointValue,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Tooltip,
  InputRightElement,
  Input,
  InputGroup,
  Button,
  Stack,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';

import {
  Button as ButtonMui,
  Typography,
  alpha,
  useTheme,
} from '@material-ui/core';
import { ArrowForwardIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import jwt_decode from 'jwt-decode';
import qs from 'qs';
import { keycloakConfig } from '../util/constants';

export default function Login() {
  const theme = useTheme();
  const history = useHistory();
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [showSenha, setShowSenha] = useState(false);
  const [erro, setErro] = useState(false);
  const [bloqueado, setBloqueado] = useState(false);
  const [enviando, setEnviando] = useState(false);

  const toast = useToast();

  const handleClickSenha = () => setShowSenha(!showSenha);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const isMobileVersion = useBreakpointValue({
    base: false,
    sm: true,
  });

  var data = qs.stringify({
    grant_type: 'password',
    client_id: keycloakConfig.resource,
    client_secret: keycloakConfig.credentials.secret,
    username: usuario.replace(/[^0-9]+/g, ''),
    password: senha,
    scope: 'openid',
  });
  var config = {
    method: 'post',
    url:
      keycloakConfig['auth-server-url'] +
      'realms/' +
      keycloakConfig.realm +
      '/protocol/openid-connect/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  };

  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const acessar = event => {
    setEnviando(true);
    axios(config)
      .then(function (response) {
        setEnviando(false);
        localStorage.setItem('tkn-access', response.data.access_token);
        localStorage.setItem('tkn-refresh', response.data.refresh_token);
        localStorage.setItem('permission', response.data.profileBackOffice);
        localStorage.setItem('userCpf', usuario.replace(/[^0-9]+/g, ''));
        localStorage.setItem('auth-ok', true);
        var decoded = jwt_decode(response.data.access_token);
        var o = new Object();
        console.log('Login Aqui: ', response.data.access_token);
        console.log('Decoded Aqui: ', decoded);

        const cookies = new Cookies();
        const roles = decoded.realm_access.roles.filter(
          element =>
            element === 'backoffice-admin' ||
            element === 'backoffice-multiplica' ||
            element === 'backoffice-empresa' ||
            element === 'backoffice-consulta' ||
            element === 'usuario_master' ||
            element === 'consulta_mercado' ||
            element === 'correntista_operador' ||
            element === 'correntista_administrador',
        );
        cookies.set('roles', roles, { path: '/backoffice' });
        cookies.set('name', o.name, { path: '/backoffice' });
        cookies.set('tkn-access', response.data.access_token, {
          path: '/backoffice',
        });

        localStorage.setItem('name', o.name);
        localStorage.setItem('nome', decoded.name);
        localStorage.setItem('id-cliente', decoded.id);
        localStorage.setItem('roles', roles);
        localStorage.setItem('profileBackOffice', decoded.profileBackOffice);
        localStorage.setItem(
          'administradorEscrow',
          decoded.administradorEscrow,
        );
        localStorage.getItem('nome');
        localStorage.setItem(
          'expiracao-refresh',
          response.data.refresh_expires_in,
        );
        localStorage.setItem('consulta_mercado', decoded.consulta_mercado);
        const tempoExpiracao = response.data.expires_in;
        const horaAtual = new Date();
        horaAtual.setSeconds(horaAtual.getSeconds() + tempoExpiracao);
        localStorage.setItem('horaAgora', horaAtual);
        const isPermicao = localStorage.getItem('profileBackOffice');
        const isPermicaoEscrow = localStorage.getItem('administradorEscrow');
        localStorage.setItem('given_name', decoded.given_name);
        if (isPermicao == 'true' && query.get('contexto') == 'backoffice') {
          window.location.href = '/backoffice';
        } else if (
          isPermicaoEscrow == 'true' &&
          query.get('contexto') == 'backoffice'
        ) {
          window.location.href = '/backoffice/monitor-aprovacao';
        } else {
          window.location.href = '/select-account';
        }
      })
      .catch(function (error) {
        setEnviando(false);
        console.log('pegou o erro', error);
        localStorage.setItem('tkn-access', '');
        localStorage.setItem('tkn-refresh', '');
        localStorage.setItem('permission', '');
        localStorage.setItem('auth-ok', false);
        localStorage.setItem('gender', '');
        localStorage.setItem('name', '');
        localStorage.setItem('given_name', '');
        if (
          error.response.status == 401 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          mostrarErro();
        }
        if (error.response.status == 400) {
          mostrarBloqueado();
        }
      });
  };

  function mostrarErro() {
    setErro(true);
  }
  function mostrarBloqueado() {
    setBloqueado(true);
  }

  const esqueciSenha = () => {
    localStorage.setItem(
      'usuario-esqueci-senha',
      usuario.replace(/[^0-9]+/g, ''),
    );
    api_multipague_configuracao
      .post(
        `/usuario/senha/enviar-email?username=${usuario.replace(
          /[^0-9]+/g,
          '',
        )}`,
      )
      .then(response => {
        history.push('/login/new-password', {
          usuario: usuario.replace(/[^0-9]+/g, ''),
        });
        toast({
          title: 'Falta pouco para você recuperar sua senha.',
          description: 'Um código de confirmação foi enviado para o seu email.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toast({
            title: 'Ops!',
            description: error.response.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <Flex
      columns={isWideVersion ? 2 : 1}
      spacing={0}
      style={{
        padding: '2.5vh 5vh',
        backgroundColor: alpha(
          theme.palette.appBar[theme.palette.type].bgMenu,
          0.9,
        ),
      }}
    >
      {isWideVersion && (
        <Box bg="transparent" height="95vh" width={'50%'} position="relative">
          <Image
            src={theme.config.loginImage}
            objectPosition="top"
            boxSize="100%"
            borderTopLeftRadius={'20px'}
            borderBottomLeftRadius={'20px'}
            objectFit="cover"
            alt={theme.config.clienteName}
          />
          <Image
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: 10,
              backgroundColor: 'transparent',
              zIndex: 99,
            }}
            src={theme.config.loginLogoLeft}
            boxSize="65%"
            objectFit="contain"
            alt={theme.config.clienteName}
          />
        </Box>
      )}
      <Box
        bg={alpha(theme.palette.background.paper, 0.95)}
        width={isWideVersion ? '50%' : '170%'}
        borderTopRightRadius={'20px'}
        borderBottomRightRadius={'20px'}
        borderBottomLeftRadius={!isWideVersion ? '20px' : 0}
        borderTopLeftRadius={!isWideVersion ? '20px' : 0}
        height="95vh"
        px={isWideVersion ? 10 : 30}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Flex
          w="100%"
          my={isMobileVersion ? '2' : '10'}
          maxWidth={1120}
          mx="auto"
          mt={'2rem'}
          justifyContent="center"
        >
          {!isWideVersion && (
            <img
              src={theme.config.loginLogo}
              alt={theme.config.clienteName}
              width="180px"
            />
          )}
        </Flex>
        <Flex
          w="100%"
          mt={!isMobileVersion ? 10 : 20}
          flexDirection={'column'}
          justifyContent="center"
          align={'center'}
        >
          <Typography
            color={theme.palette.text.primary}
            style={{
              textAlign: 'center',
              fontSize: isMobileVersion ? '25px' : '15px',
              fontWeight: '900',
            }}
            variant="h3"
            component={'h3'}
          >
            Bem-Vindo!
          </Typography>
          <Typography
            color="text-primary"
            style={{
              marginTop: '10px',
              fontSize: isMobileVersion ? '15px' : '10px',
            }}
            variant="h4"
            component={'h4'}
          >
            Acesse seu ambiente do {theme.config.clienteName}!
          </Typography>
        </Flex>

        <FormControl
          maxWidth={isMobileVersion ? '60%' : '100%'}
          px={isMobileVersion ? '6' : '2'}
          margin="auto"
        >
          <Input
            as={InputMask}
            maskChar={null}
            mask="999.999.999-99"
            borderRadius={'12px'}
            borderColor="gray.300"
            id="cpf"
            placeholder="Usuário (CPF)"
            type="text"
            _focus={{
              borderColor: theme.palette.text.disabled,
              backgroundColor: alpha(theme.palette.primary.light, 0.2),
            }}
            height={'45px'}
            onChange={e => {
              setUsuario(e.target.value);
            }}
          />
          <InputGroup size="md" marginTop={6}>
            <Input
              borderColor="gray.300"
              id="password"
              borderRadius={'12px'}
              height={'45px'}
              placeholder="Senha"
              type={showSenha ? 'text' : 'password'}
              _focus={{
                borderColor: theme.palette.text.disabled,
                backgroundColor: alpha(theme.palette.primary.light, 0.2),
              }}
              onChange={e => {
                setSenha(e.target.value);
              }}
            />
            <InputRightElement marginTop={0.5} width="3.5rem">
              <Button
                borderRadius={'90px'}
                h="2.2rem"
                size="sm"
                onClick={handleClickSenha}
              >
                {showSenha ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>

          <Flex
            w="100%"
            my="3"
            maxWidth={1120}
            mx="auto"
            justifyContent="center"
          >
            <Tooltip
              color={theme.palette.background.paper}
              backgroundColor={theme.palette.primary.main}
              label="Preencha o campo de CPF"
              aria-label="A tooltip"
            >
              <ButtonMui
                style={{
                  textTransform: 'initial',
                  borderRadius: '10px',
                  border: 'none',
                }}
                variant="outlined"
                color="primary"
                size="small"
                onClick={usuario.length == 14 ? () => esqueciSenha() : ''}
              >
                Esqueceu sua senha?
              </ButtonMui>
            </Tooltip>
          </Flex>

          <Stack spacing={3} textAlign="center">
            {erro && (
              <Text fontSize={isMobileVersion ? 'sm' : 'xs'} color="red.500">
                Usuário ou senha inválidos. Tente novamente.
              </Text>
            )}
            {bloqueado && (
              <Text fontSize={isMobileVersion ? 'sm' : 'xs'} color="red.500">
                Sua conta está temporariamente bloqueada. Para mais informações
                entre em contato através do e-mail
                beyondbank@grupomultiplica.com.br
              </Text>
            )}
          </Stack>

          <Flex mt={'30px'} display={'flex'} justifyContent="center">
            <ButtonMui
              variant="contained"
              color="primary"
              endIcon={
                <ArrowForwardIcon color={theme.palette.background.paper} />
              }
              style={{
                boxShadow: 'none',
                width: '100%',
                borderRadius: '10px',
              }}
              type="submit"
              onClick={() => acessar()}
              disabled={usuario.length == 14 && senha != '' ? false : true}
            >
              <Typography
                style={{
                  color: theme.palette.background.paper,
                  fontFamily: 'Helvetica',
                  lineHeight: 'normal',
                  fontWeight: 900,
                }}
                color={theme.palette.text.primary}
              >
                Entrar
              </Typography>
            </ButtonMui>
          </Flex>
        </FormControl>

        <Stack spacing={3} textAlign="center" mb="6" mt={'20'}>
          <Text fontSize={isMobileVersion ? 'sm' : 'xs'} color="gray.800">
            <a href="../new-register">
              Ainda não tem uma conta? <strong>Crie agora</strong>
            </a>
          </Text>
          <Text fontSize={isMobileVersion ? 'sm' : 'xs'} color="gray.800">
            <a href="/login/faq-login">
              Dúvidas para abertura de conta? <strong>Clique aqui</strong>
            </a>
          </Text>
          <Text fontSize={isMobileVersion ? 'sm' : 'xs'} color="gray.800">
            &copy; {new Date().getFullYear()}
            {'.'} Grupo Multiplica. {theme.config.clienteName}
            {' | '}
            Todos os direitos reservados.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
}
