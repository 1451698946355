import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import Button2 from '../components/Buttons/ConfirmButton';
import {  useParams, useLocation  } from "react-router-dom"
import { api_multipague_confirmEmail } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import LoadingBtn from '../components/Buttons/ConfirmButton'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Todos os Direitos Reservados.
      <Link color="inherit" href="https://grupomultiplica.com.br/">
        Grupo Multiplica
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#394C59'
  },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default function ConfirmEmail() {
  const classes = useStyles();
  const query = useQuery();
  const [financial, setFinancial] = useState('');
  const [loadButton, setLoadButton] = useState(false)
  const { tk } = useParams()
//   const [token, setTk] = useState('');
  var md5 = require('md5');

  const options = {
    headers: {
        'x-autenticacao-financeira': (md5(financial))
      }
  }

  const [values, setValues] = React.useState({
    showPassword: false,
    showPasswordConfirm: false,
    showPasswordConfirmFin: false,
    showPasswordFin: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

const handleAbrirConta = value => {
    setLoadButton(true)
    api_multipague_confirmEmail.post(`/ativarConta?token=${query.get('tk')}`,{} ,options)
//   api_multipague_confirmEmail.post(`/ativarConta?token=${query.get("tk")}`, options)
      .then(response => {
        setLoadButton(false)
        toast.success('Ok! Sua conta foi ativada com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setTimeout(function(){ window.location.href = "/login" },3000)

      }
      )
      .catch(error => {
        console.log(error);
        setLoadButton(false)
        if(error.response.status == 400 || error.response.status == 404 || error.response.status == 409 || error.response.status == 500){
          // alert(error.response.data.mensagem)
          toast.error(error.response.data.mensagem, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
            );
        }
      });
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Ative sua conta
        </Typography>
        <form className={classes.form} noValidate>
        <InputLabel shrink htmlFor="age-native-label-placeholder">
        Digite a sua senha financeira
            </InputLabel>
          <OutlinedInput
            margin="dense"
            variant="outlined"
            size="small"
            type={values.showPassword ? 'text' : 'password'}
            required
            fullWidth
            id="financialpassword"
            name="financialpassword"
            onChange={(e) => { setFinancial(e.target.value) }}
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {/* <input hidden value={this.props.location.search.tk} onChange={(e) => { setTk(e.target.value) }} /> */}

          <LoadingBtn
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            isLoading={loadButton}
            onClick={handleAbrirConta}
          >
            Ativar
          </LoadingBtn>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
        <ToastContainer />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
