import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Login from '../pages/index';
import FaqLogin from '../pages/faq';
import ConfirmEmail from '../pages/confirm-email';
import NewPassword from '../pages/new-password';
import SelectAccount from '../pages/select-account';

import { RegistrarSenha } from '../pages/ConfirmSenha';
import themeFake from '../theme/theme-bank.json';
import { generateApiApp } from '../theme/api.theme';
import { Box, CssBaseline, makeStyles } from '@material-ui/core';

const ThemeClient = () => {
  alert('Vai ser gerado tema de um cliente fake');
  useEffect(() => {
    localStorage.removeItem('app-config');
    generateApiApp('fake').then(() => {
      window.location.href = '/login';
    });
  }, []);
  return <p>Vai ser gerado tema de um cliente fake</p>;
};

const ThemeClientDefault = () => {
  alert('Sera gerado o tema padrao do multsoluções');
  useEffect(() => {
    localStorage.removeItem('app-config');
    generateApiApp().then(() => {
      window.location.href = '/login';
    });
  }, []);
  return <p>Sera gerado o tema padrao do multsoluções</p>;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  main: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    color:
      theme.palette.type === 'dark'
        ? theme.palette.getContrastText(theme.palette.secondary.main)
        : theme.palette.secondary.main,
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
  },
}));

function Routes() {
  const classes = useStyles();

  return (
    <Box style={{ display: 'flex' }}>
      <CssBaseline />
      <Box className={classes.main}>
        <BrowserRouter>
          <Switch>
            {/* // Will`s Screen */}
            <Route path="/login" exact component={Login} />
            <Route path="/login/faq-login" exact component={FaqLogin} />
            <Route path="/login/confirm-email" component={ConfirmEmail} />
            <Route path="/login/new-password" component={NewPassword} />
            <Route path="/login/select-account" component={SelectAccount} />
            <Route path="/login/CadastrarSenha" component={RegistrarSenha} />
            <Route path="/login/fake" component={ThemeClient} />
            <Route path="/login/reset" component={ThemeClientDefault} />
          </Switch>
        </BrowserRouter>
      </Box>
    </Box>
  );
}

export default Routes;
